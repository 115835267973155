import './App.css';

import React, {Component, useEffect, useState} from 'react';
import HeaderForm from './header/Form'
import FooterForm from './footer/Form'

import Area1 from "./home/area1/Form";
import Area2 from './home/area2/Form'
import Area3 from './home/area3/Form'
import Area4 from './home/area4/Form'
import Area44 from './home/area44/Form'

import Area5 from './home/area5/Form'
import Area6 from "./home/area6/Form";
import Area7 from './home/area7/Form'
import Area8 from "./home/area8/Form";
import Area9 from "./home/area9/Form";
import Area10 from './home/area10/Form'
import Area11 from "./home/area11/Form";
import Area12 from "./home/area12/Form";
import Area13 from "./home/area13/Form";
import Area14 from "./home/area14/Form";
import Area40 from "./home/area40/Form";
import KonfiguratorForm from "./konfigurator/Form";
import KontaktForm from "./kontakt/Form";
import LawContact from "./law/contact/Form";
import LawImpressum from "./law/impressum/Form";
import LawAgb from "./law/agb/Form";
import LawPrivacy from "./law/privacy/Form";
import VoiceList from "./voice/List";
import KarriereForm from "./karriere/Form";
import TeamForm from "./team/Form";
import RequestreceivedForm from "./requestreceived/Form";

import LeistungenLeadgenerierungForm from "./leistungen/leadgenerierung/Form";
import LeistungenProzesserklaerungForm from "./leistungen/prozesserklaerung/Form";
import LeistungenRecurtingvideoForm from "./leistungen/recurtingvideo/Form";
import LeistungenSchulungsvideoForm from "./leistungen/schulungsvideo/Form";
import LeistungenSoftwarevideoForm from "./leistungen/softwarevideo/Form";
import LeistungenVenclipForm from "./leistungen/venclip/Form";

import {Route, BrowserRouter as Router} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import ChatBox from "./chatbox/ChatBox";

function App() {
    const [forceUpdate, setForceUpdate] = useState(true);


    const startRef = React.createRef()
    const unserePakete = React.createRef()
    const referenzen = React.createRef()
    const dasSindWir = React.createRef()
    const kontaktRef = React.createRef()
    const konfiguratorRef = React.createRef()


    const services = {
        checkTime: () => {
            let hour = new Date().getHours()
            if(hour == 9 || hour == 10 || hour == 11 || hour == 12 || hour == 13 || hour == 14 || hour == 15 || hour == 16 || hour == 17 ) {
                return true
            }
            return false
        },
    }

    return (
        <div className="App">
            {services.checkTime() && (<ChatBox/>)}

            <HeaderForm
                onItemSelect={(item) => {
                    if(item == 'START') {
                        if(startRef.current == null) {
                            window.location.href = "/"
                        } else {
                            startRef.current.scrollIntoView()
                        }
                    }

                    if(item == 'UNSERE_PAKETE') {
                        if(unserePakete.current == null) {
                            window.location.href = "/"
                        } else {
                            unserePakete.current.scrollIntoView()
                        }
                    }

                    if(item == 'KONFIGURATOR') {
                        if(unserePakete.current == null) {
                            window.location.href = "/"
                        } else {
                            konfiguratorRef.current.scrollIntoView()
                        }
                    }

                    if(item == 'REFERENZEN') {
                        if(referenzen.current == null) {
                            window.location.href = "/"
                        } else {
                            referenzen.current.scrollIntoView()
                        }
                    }

                    if(item == 'DAS_SIND_WIR') {
                        if(dasSindWir.current == null) {
                            window.location.href = "/"
                        } else {
                            dasSindWir.current.scrollIntoView()
                        }
                    }

                    if(item == 'KONTAKT') {
                        window.location.href = "/kontakt"
                    }
                }}
            />

            <Router>
                <Route exact path="/" render={(props) => (

                    <div>
                        <Area1 childRef={startRef} onAngebotSichernClick={() => konfiguratorRef.current.scrollIntoView()}/>
                        <Area2/>
                        <Area3/>
                        <Area4 childRef={kontaktRef}/>
                        <Area44/>
                        <Area5 childRef={referenzen}/>
                        <Area6/>
                        <Area7 childRef={dasSindWir}/>
                        <Area8/>
                        <Area9/>
                        <Area10/>
                        <Area11/>
                        <Area12 childRef={unserePakete}/>
                        <Area13 childRef={konfiguratorRef}/>
                        <Area14/>
                        <Area40/>
                    </div>
                )} />
                <Route path="/konfigurator" render={() => <KonfiguratorForm/>}/>

                <Route path="/contact" component={LawContact} />
                <Route path="/impressum" render={() => (
                    <div>
                        <LawContact/>
                        <LawImpressum/>
                    </div>
                )}/>
                <Route path="/agb" render={() => (
                    <div>
                        <LawAgb/>
                    </div>
                )}/>
                <Route path="/datenschutz" render={() => (
                    <div>
                        <LawContact/>
                        <LawPrivacy/>
                    </div>
                )}/>
                <Route path="/sprecherstimmen" component={VoiceList} />
                <Route path="/preise" render={() => (
                    <div>
                        <Area12/>
                        <Area13/>
                        <br/>
                        <br/>
                        <KontaktForm/>
                    </div>
                )}/>
                <Route path="/kontakt" component={KontaktForm} />
                <Route path="/karriere" component={KarriereForm} />
                <Route path="/team" component={TeamForm} />
                <Route path="/referenzen" render={() => (
                    <div>
                        <Area5 childRef={referenzen} showMore={true}/>
                        <Area6/>
                        <Area12/>
                        <KontaktForm/>
                    </div>
                )} />
                <Route path="/danke/silver" component={RequestreceivedForm}/>
                <Route path="/danke/gold" component={RequestreceivedForm}/>
                <Route path="/danke/premium" component={RequestreceivedForm}/>
                <Route path="/danke/jetztKontaktAufnehmen" component={RequestreceivedForm}/>
                <Route path="/danke/kontakt" component={RequestreceivedForm}/>
                <Route path="/danke/konfigurator" component={RequestreceivedForm}/>

                <Route path="/leistungen/schulungsvideo" component={LeistungenSchulungsvideoForm}/>
                <Route path="/leistungen/recurtingvideo" component={LeistungenRecurtingvideoForm}/>
                <Route path="/leistungen/softwarevideo" component={LeistungenSoftwarevideoForm}/>
                <Route path="/leistungen/leadgenerierung" component={LeistungenLeadgenerierungForm}/>
                <Route path="/leistungen/prozesserklaerung" component={LeistungenProzesserklaerungForm}/>
                <Route path="/leistungen/venclip" component={LeistungenVenclipForm}/>
            </Router>


            <FooterForm/>
        </div>
    );

}
export default App;
